var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("h3", [
        _vm._v("\n      " + _vm._s(_vm.selectedRegistration.name) + "\n    "),
      ]),
    ]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("validation-observer", {
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ invalid }) {
                return [
                  _c(
                    "div",
                    { staticClass: "form-grid" },
                    _vm._l(_vm.fields, function (field, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "form-item" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: field.label, horizontal: "" } },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  rules: field.required
                                    ? `required|${field.key.toString()}`
                                    : null,
                                  name: field.label,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          field.type === "number"
                                            ? [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    min: field.min,
                                                    max: field.max,
                                                    type: "number",
                                                    step: "1",
                                                    state: !errors[0],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "number-lux"
                                            ? [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    min: field.min,
                                                    max: field.max,
                                                    type: "number",
                                                    step: "1",
                                                    state: !errors[0],
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.determineFixedValues,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "text"
                                            ? [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    state: !errors[0],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "vin"
                                            ? [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    type: "text",
                                                    "allow-empty": false,
                                                    multiple: false,
                                                    state: !errors[0],
                                                  },
                                                  on: {
                                                    input: _vm.retrieveVinInfo,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "boolean" &&
                                          _vm.newVehicle[
                                            "registration_period"
                                          ] !== "permanent"
                                            ? [
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    "button-variant": "success",
                                                    switch: "",
                                                    size: "lg",
                                                    state: !errors[0],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "select"
                                            ? [
                                                _c("multiselect", {
                                                  attrs: {
                                                    options: field.values,
                                                    "allow-empty": false,
                                                    multiple: false,
                                                    disabled: field["disabled"],
                                                    "deselect-label": "",
                                                    "select-label": "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type ===
                                          "select-fuel-add-ev-hybrid-tax"
                                            ? [
                                                _c("multiselect", {
                                                  attrs: {
                                                    options: field.values,
                                                    "allow-empty": false,
                                                    multiple: false,
                                                    "deselect-label": "",
                                                    "select-label": "",
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.determineEvHybridTax,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "select-luxury_tax"
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        options: field.values,
                                                        label: "text",
                                                        "track-by": "value",
                                                        "allow-empty": false,
                                                        multiple: false,
                                                        "deselect-label": "",
                                                        "select-label": "",
                                                        disabled: "disabled",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newVehicle[
                                                            field.key
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newVehicle,
                                                            field.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newVehicle[field.key]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          field.type ===
                                          "select-electric-hybrid-tax"
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        options: field.values,
                                                        label: "text",
                                                        "track-by": "value",
                                                        "allow-empty": false,
                                                        multiple: false,
                                                        "deselect-label": "",
                                                        "select-label": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newVehicle[
                                                            field.key
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newVehicle,
                                                            field.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newVehicle[field.key]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          field.type === "select-text-name"
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        options: field.values,
                                                        label: "text",
                                                        "track-by": "name",
                                                        "allow-empty": false,
                                                        multiple: false,
                                                        "deselect-label": "",
                                                        "select-label": "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.newVehicle[
                                                            field.key
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.newVehicle,
                                                            field.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "newVehicle[field.key]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          field.type === "select-make"
                                            ? [
                                                _c("multiselect", {
                                                  attrs: {
                                                    "tag-placeholder":
                                                      "Add as a new make",
                                                    placeholder:
                                                      "Search or add a make",
                                                    options: _vm.vehicleMakes,
                                                    "allow-empty": false,
                                                    multiple: false,
                                                    taggable: true,
                                                    "deselect-label": "",
                                                    "select-label": "",
                                                  },
                                                  on: {
                                                    tag: _vm.addMake,
                                                    changed: _vm.retrieveModels,
                                                    input: _vm.retrieveModels,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          field.type === "select-model"
                                            ? [
                                                _c("multiselect", {
                                                  attrs: {
                                                    "tag-placeholder":
                                                      "Add as a new model",
                                                    placeholder:
                                                      "Search or add a model",
                                                    options: _vm.vehicleModels,
                                                    taggable: true,
                                                    "track-by": "value",
                                                    multiple: false,
                                                    "deselect-label": "",
                                                    "select-label": "",
                                                  },
                                                  on: { tag: _vm.addModel },
                                                  model: {
                                                    value:
                                                      _vm.newVehicle[field.key],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newVehicle,
                                                        field.key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newVehicle[field.key]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(_vm._s(errors[0])),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center pt-4" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            type: "submit",
                            "aria-label": "Continue to checkout button",
                            disabled: invalid,
                          },
                        },
                        [_vm._v("\n          Continue to Checkout\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }